// Bootstrap brand color customization
// $brand-primary: $purple !default;
$brand-primary: #90CDD6 !default;
// $brand-success: $green !default;
$brand-success: #90AEE0 !default;
$brand-danger: $red !default;
// $brand-danger: #F74F8C !default;
$brand-warning: $orange !default;
$brand-info: $cyan !default;
// $brand-rose: $pink !default;
$brand-rose: #F74F8C !default;
