//https://www.google.com/design/spec/components/cards.html#cards-content-blocks
// Card resting elevation: 2dp
.card {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: $border-radius-large;
  color: $gray-dark;
  background: $white-color;
  width: 100%;

    .card-category:not([class*="text-"]) {
        color: $gray-color;
    }
    .card-category{
        margin-top: 10px;

        .material-icons{
            position: relative;
            top: 8px;
            line-height: 0;
          }
  }

  .form-check {
      margin-top: 15px;
  }

    .card-title{
        margin-top: 0.625rem;

        &:last-child{
            margin-bottom: 0;
        }
    }

  // Cards have a default elevation of 2dp.
  @include box-shadow($bmd-shadow-2dp);
  @extend %std-font;


  &.no-shadow {
    .card-header-image,
    .card-header-image img {
      box-shadow: none !important;
      }
  }

  .card-body,
  .card-footer {
    padding: $padding-card-body-y $padding-card-body-x;
  }

  .card-body {
      & + .card-footer{
        padding-top: 0rem;
        border: 0;
        border-radius: $border-radius-large;
      }
  }

  .card-footer {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;

    .author,
    .stats {
        display: inline-flex;
    }

    .stats {
        color: $gray-color;

        .material-icons {
            position: relative;
            top: -10px;
            margin-right: 3px;
            margin-left: 3px;
            font-size: 18px;
        }
    }
  }

  &.bmd-card-raised {
    // Card raised elevation: 8dp
    @include box-shadow($bmd-shadow-8dp);
  }

  @include media-breakpoint-up(lg) {
    // On desktop, cards can have a resting elevation of 0dp and gain an elevation of 8dp on hover.
    &.bmd-card-flat {
      box-shadow: none;
    }
  }

    .card-header {
        border-radius: $border-radius-base;
        padding: $bmd-label-top-margin-base 15px;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: -30px;
        border: 0;
        background: linear-gradient(60deg, $grey-200, $grey-400);

        .title{
            color: $white-color;
        }

        &:not([class*="header-"]){
            @include shadow-big();
        }

        .nav-tabs {
            padding: 0;
        }

        &.card-header-image {
            position: relative;
            padding: 0;
            z-index: 1;

            margin-left: 15px;
            margin-right: 15px;
            margin-top: -30px;
            border-radius: $border-radius-large;

            a {
              display: block;
            }

            img {
                width: 100%;
                border-radius: $border-radius-large;
                pointer-events: none;
                @include shadow-big-image();
            }
            .card-title {
                position: absolute;
                bottom: 15px;
                left: 15px;
                color: $white-color;
                font-size: $font-size-h4;
                text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
            }

            .colored-shadow{
                transform: scale(0.94);
                top: 12px;
                filter: blur(12px);
                position: absolute;
                width: 100%;
                height: 100%;
                background-size: cover;
                z-index: -1;
                transition: opacity .45s;
                opacity: 0;
            }

            &.no-shadow{
                box-shadow: none;

                &.shadow-normal{
                    @include shadow-big();
                }

                .colored-shadow{
                    display: none !important;
                }
            }
        }
    }

    .card-header-primary,
    &.bg-primary,
    &.card-rotate.bg-primary .front,
    &.card-rotate.bg-primary .back{
      background: linear-gradient(60deg, $purple-400, $purple-700);
    }
    .card-header-info,
    &.bg-info,
    &.card-rotate.bg-info .front,
    &.card-rotate.bg-info .back{
      background: linear-gradient(60deg, $cyan-400, $cyan-700);
    }
    .card-header-success,
    &.bg-success,
    &.card-rotate.bg-success .front,
    &.card-rotate.bg-success .back{
      background: linear-gradient(60deg, $green-400, $green-700);
    }
    .card-header-warning,
    &.bg-warning,
    &.card-rotate.bg-warning .front,
    &.card-rotate.bg-warning .back{
      background: linear-gradient(60deg, $orange-400, $orange-700);
    }
    .card-header-danger,
    &.bg-danger,
    &.card-rotate.bg-danger .front,
    &.card-rotate.bg-danger .back{
      background: linear-gradient(60deg, $red-400, $red-700);
    }

    .card-header-rose,
    &.bg-rose,
    &.card-rotate.bg-rose .front,
    &.card-rotate.bg-rose .back{
      background: linear-gradient(60deg, $pink-400, $pink-700);
    }

    .card-header-primary{
        @include shadow-big-color($brand-primary);

        //@include shadow-8dp-color($brand-primary);
        //@include shadow-16dp-color($brand-primary);
    }
    .card-header-danger{
        @include shadow-big-color($brand-danger);
    }

    .card-header-rose{
        @include shadow-big-color($brand-rose);
    }

    .card-header-warning{
        @include shadow-big-color($brand-warning);
    }

    .card-header-info{
        @include shadow-big-color($brand-info);
    }

    .card-header-success{
        @include shadow-big-color($brand-success);
    }

    [class*="header-"],
    &[class*="bg-"]{
        color: $white-color;

        .card-title a,
        .card-title,
        .icon i{
            color: $white-color;
        }

        .icon i{
            border-color: rgba(255, 255, 255, 0.25);
        }
        .author a,
        .stats,
        .card-category,
        .card-description{
            color: $white-transparent;
        }

        .author a{
            &:hover,
            &:focus,
            &:active{
                color: $white-color;
            }
        }
    }

    .author{
        .avatar{
            width: 30px;
            height: 30px;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 5px;
        }

        a{
            color: $black-color;
            text-decoration: none;

            .ripple-container{
                display: none;
            }
        }
    }

    .card-category-social{
        .fa{
            font-size: 24px;
            position: relative;
            margin-top: -4px;
            top: 2px;
            margin-right: 5px;
        }

        .material-icons{
            position: relative;
            top: 5px;
        }
    }

    &[class*="bg-"],
    &[class*="bg-"] .card-body{
        border-radius: $border-radius-large;

        h1,
        h2,
        h3{
          small{
              color: $white-transparent;
          }
        }
    }

    .card-stats{
        background: transparent;
        display: flex;

        .author,
        .stats{
            display: inline-flex;
        }
    }
}


.card-plain {
    background: transparent;
    box-shadow: none;

    .card-header:not(.card-avatar) {
        margin-left: 0;
        margin-right: 0;
    }

    .card-body {
        padding-left: 5px;
        padding-right: 5px;
    }

    .card-header-image {
        margin: 0 !important;
        border-radius: $border-radius-large;

        img {
          border-radius: $border-radius-large;
        }
    }
    .card-footer {
        padding-left: 5px;
        padding-right: 5px;
        background-color: transparent;
    }
}
